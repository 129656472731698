<template>
  <div class="levelOneDiv" style="background-color: #fff;">
    <!-- <div style="padding: 10px 20px 10px; ">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="item in breadList" :key="item.key">{{ item.value }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div v-if="!componentName" class="table_box">
      <div class="box1">
        <templateTree ref="templateTree" :treeData="treeData" :dck="dck" @refreshNode="refreshNode" />
      </div>

      <div class="box2">
        <div class="table_right">
          <!-- 搜索筛选 -->
          <el-form
            :inline="true"
            :model="searchObj"
            :rules="rules"
            ref="form"
            style="align-items: center;margin-bottom: 4px;"
            class="user-search flex-between"
            @submit.native.prevent
          >
            <div>
              <el-form-item label="预算年度" prop="year">
                <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
              </el-form-item>

              <!-- <el-form-item label="选择文件" prop="year">
              <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
              </el-form-item> -->

              <el-form-item>
                <el-button size="mini" plain icon="el-icon-search" @click="search"></el-button>
              </el-form-item>

              <el-form-item>
                <el-button size="mini" plain icon="el-icon-refresh-right" @click="reset"></el-button>
              </el-form-item>
            </div>

            <div>
              <el-form-item>
                <el-button size="mini" type="primary" icon="el-icon-plus" @click="handleAdd1()">添加政策文件</el-button>
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" icon="el-icon-plus" @click="handleAdd('budget')"
                  >预算支付</el-button
                >
                <!-- <el-button size="mini" type="primary" icon="el-icon-plus" @click="handleAdd('payment')">支付</el-button> -->
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-download" @click="importExcel()"
                  >导入</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-upload2" @click="exportExcel()"
                  >导出</el-button
                >
              </el-form-item>

              <!-- <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-s-data" @click="handleView('charts', {})"
                  >查看统计图</el-button
                >
              </el-form-item> -->
            </div>
          </el-form>

          <div style="position: relative;">
            <el-table
              v-loading="loading"
              ref="table1"
              :data="listData"
              style="width: 100%;position: absolute;"
              height="740px"
              :span-method="objectSpanMethod"
            >
              <el-table-column fixed type="index" label="序号" width="60" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.$index != 0">{{
                    (pageparm.currentPage - 1) * pageparm.pageSize + scope.$index
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column fixed prop="year" align="center" width="80" label="年度">
                <template slot-scope="scope">
                  <span v-if="scope.$index != 0">{{ scope.row.year }}</span>
                  <span v-else>{{ scope.row.county }}</span>
                </template>
              </el-table-column>
              <el-table-column fixed prop="county" align="center" width="80" label="行政区域">
                <template slot-scope="scope">
                  <span v-if="scope.$index != 0">{{ scope.row.county }}</span>
                  <span v-else></span>
                </template>
              </el-table-column>
              <el-table-column
                fixed
                prop="documentNumber"
                align="center"
                width="150"
                style="word-break: normal"
                label="文号"
              >
                <template slot-scope="scope">
                  <el-tooltip placement="top-start">
                    <div slot="content" class="text_warp">
                      {{ scope.row.documentNumber }}
                    </div>
                    <div style="color: #000" class="text_els">
                      {{ scope.row.documentNumber }}
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                fixed
                prop="documentName"
                align="center"
                width="150"
                style="word-break: normal"
                label="文件名称"
              >
                <template slot-scope="scope">
                  <el-tooltip placement="top-start">
                    <div slot="content" class="text_warp">
                      {{ scope.row.documentName }}
                    </div>
                    <div style="color: #000" class="text_els">
                      {{ scope.row.documentName }}
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>

              <el-table-column prop="issuedDate" width="120" align="center" label="下达时间">
                <template slot-scope="scope">
                  <div v-if="scope.$index != 0">
                    {{ scope.row.issuedDate ? timeFormate(scope.row.issuedDate, 'YYYY-MM-DD') : '' }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column prop="totalAmount" width="120" align="center" label="总金额（万元）">
                <template slot-scope="scope">
                  {{ formatToTenThousandWithCustomCommas(scope.row.totalAmount) }}
                </template>
              </el-table-column>
              <el-table-column prop="budgetAmount" width="140" align="center" label="预算资金（万元）">
                <template slot-scope="scope">
                  {{ formatToTenThousandWithCustomCommas(scope.row.budgetAmount) }}
                </template>
              </el-table-column>

              <el-table-column align="center" label="截至2022年12月31日资金支付情况">
                <el-table-column align="center" width="170" prop="yearlyTotalPayments" label="累计支付总额（万元）">
                  <template slot-scope="scope">
                    {{ formatToTenThousandWithCustomCommas(scope.row.yearlyTotalPayments) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" width="170" prop="yearlyBookBalance" label="账面结存资金（万元）">
                  <template slot-scope="scope">
                    {{ formatToTenThousandWithCustomCommas(scope.row.yearlyBookBalance) }}
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" label="截至2023年3月31日资金支付情况">
                <el-table-column align="center" width="170" prop="nextYearQ1TotalPayments" label="累计支付总额（万元）">
                  <template slot-scope="scope">
                    {{ formatToTenThousandWithCustomCommas(scope.row.nextYearQ1TotalPayments) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" width="170" prop="nextYearQ1BookBalance" label="账面结存资金（万元）">
                  <template slot-scope="scope">
                    {{ formatToTenThousandWithCustomCommas(scope.row.nextYearQ1BookBalance) }}
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  width="170"
                  prop="nextYearQ1ReclaimedByFinance"
                  label="累计支付总额（万元）"
                >
                  <template slot-scope="scope">
                    {{ formatToTenThousandWithCustomCommas(scope.row.nextYearQ1ReclaimedByFinance) }}
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column fixed="right" align="center" width="120" prop="name" label="操作">
                <template slot-scope="scope">
                  <div class="flex" style="justify-content: center;">
                    <el-link v-if="scope.$index != 0" type="primary" @click="handleView('budget', scope.row)"
                      >查看</el-link
                    >
                    &nbsp; &nbsp;

                    <!-- <el-link v-if="scope.$index != 0" type="success" @click="handleView('payment', scope.row)"
                    >支付</el-link
                  >
                  &nbsp; &nbsp; -->
                    <el-link v-if="scope.$index != 0" type="danger" @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-link
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        </div>
      </div>
    </div>

    <policyDocument v-if="componentName == 'policyDocument'" ref="policyDocument" @handleBack="handleBack">
    </policyDocument>

    <handleBudget
      v-if="componentName == 'budget'"
      ref="handleBudget"
      @refreshTable="refreshTable"
      @handleBack="handleBack"
    ></handleBudget>

    <handlePayment
      v-if="componentName == 'payment'"
      ref="handlePayment"
      @refreshTable="refreshTable"
      @handleBack="handleBack"
      @changBrumb="changBrumb"
    ></handlePayment>

    <tempalteImport ref="tempalteImport" @refreshTable="refreshTable" />
    <!-- 分页组件 -->
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import templateTitle from '@/components/templateView/templateTitle.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'
import tempalteImport from '@/components/templateView/tempalteImport.vue'
import templateTree from '@/components/templateView/templateTree.vue'
import handleBudget from '@/views/postImmigrationService/monitorAndTheUse/useOfFunds/components/handleBudget.vue'
import handlePayment from '@/views/postImmigrationService/monitorAndTheUse/useOfFunds/components/handlePayment.vue'
import policyDocument from '@/views/postImmigrationService/monitorAndTheUse/useOfFunds/components/policyDocument.vue'
import { _getLevelRegion } from '@/api/keepHome'

import {
  _BudgetExpenditurePage,
  _BudgetExpenditureDelete,
  _BudgetExpenditureExport
} from '@/api/modular/postImmigrationService/monitorAndTheUse/useOfFunds.js'

export default {
  data() {
    return {
      type: '',
      showActions: false,
      selectedRows: [], // 当前选中的行
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      },
      loading: false, //是显示加载
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择预算年度', trigger: 'blur' }]
      },
      listData: [],
      treeData: [],
      breadList: [
        {
          id: Math.random(),
          value: '列表页'
        }
      ],
      componentName: '',
      downloadObj: {},
      dck: [],
      OrgId: null,
      IsLevel: false,
      OrgPids: null,
      years: []
    }
  },
  // 注册组件
  components: {
    Pagination,
    templateTitle,
    handleBudget,
    handlePayment,
    tempalteImport,
    budgetYear,
    templateTree,
    policyDocument
  },
  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.init()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    init() {
      this.getLevelRegion()
      // this.getPage()
    },
    handleResize() {
      this.$nextTick(() => {
        this.$refs.table1.doLayout()
      })
    },
    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data
          let provincialCenterId = this.treeData[0].id //默认展开第一个节点
          this.dck = [provincialCenterId]
          this.downloadObj = this.treeData[0]
        }
      })
    },
    refreshNode(obj, IsLevel) {
      this.OrgId = obj.id
      this.downloadObj = obj
      this.IsLevel = IsLevel
      this.OrgPids = obj.pids
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }
      this.getPage()
    },
    refreshYear(year, years) {
      this.searchObj.year = year
      this.years = years
      this.pageparm.currentPage = 1
      this.getPage()
    },
    exportExcel() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let params = {
            year: this.searchObj.year,
            OrgId: this.OrgId, //行政区域编号
            OrgName: this.downloadObj.name, //行政区域名称
            OrgPids: this.downloadObj.pids //行政区域编号path
          }

          _BudgetExpenditureExport(params).then(res => {
            if (res.status == 200) {
              this.downloadfile(res)
            } else {
              this.$message.error('导出失败')
            }
          })
        } else {
          this.$message.error('请选择导出年度')
        }
      })
    },
    importExcel() {
      this.$refs.tempalteImport.view(this.searchObj.year, this.years, '2-1')
    },

    handleBack() {
      this.breadList.splice(1, 1)
      this.componentName = ''
    },
    // 获取page列表
    getPage() {
      const { currentPage, pageSize, total } = this.pageparm
      this.loading = true
      const params = {
        pageNo: currentPage,
        pageSize: pageSize,
        ...this.searchObj,
        OrgId: this.OrgId,
        OrgPids: this.OrgPids,
        IsLevel: this.IsLevel
      }

      // return
      _BudgetExpenditurePage(params)
        .then(res => {
          if (res.code == 200) {
            this.listData = res.data.rows

            this.pageparm.total = res.data.totalRows

            if (res.data.totalRow) {
              this.listData.unshift(res.data.totalRow)
            }
            

            this.loading = false
          } else {
            this.loading = false
            this.$message.error('获取失败：' + res.message)
          }
        })
        .finally(res => {
          this.loading = false
        })
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = [{ id: row.id }]
          _BudgetExpenditureDelete(params).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.getPage()
            } else {
              this.$message({
                type: 'error',
                message: res.message
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 搜索事件
    search() {
      this.pageparm.currentPage = 1
      this.getPage()
    },
    reset() {
      let nowYear = new Date().getFullYear()
      if (nowYear <= 2023) {
        this.searchObj = {
          year: nowYear - 1
        }
      } else {
        this.searchObj = {
          year: nowYear - 2
        }
      }

      this.OrgId = null
      this.IsLevel = false
      this.OrgPids = null
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }

      this.$refs.budgetYear.reset(this.searchObj.year)
      this.$refs.templateTree.resetLevel()

      this.getPage()
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 2) {
        const _row = this.flitterData(this.listData).one[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    flitterData(arr) {
      // 合并表格第一列
      let spanOneArr = []
      let concatOne = 0
      arr.forEach((item, index) => {
        if (index >= 1) {
          // 这里的type_test是表格绑定的字段，也就是需要合并的字段，根据自己的需求来改
          if (item.county == arr[index - 1].county) {
            // 第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1
            spanOneArr.push(0)
          } else {
            spanOneArr.push(1)
            concatOne = index
          }
        } else {
          spanOneArr.push(1)
        }
      })
      return {
        one: spanOneArr
      }
    },

    handleAdd(type) {
      let obj = {
        id: Math.random(),
        value: '新增'
      }
      this.breadList.splice(this.breadList.length, 0, obj)
      this.componentName = type
      this.type = type
      if (type == 'budget') {
        this.$nextTick(() => {
          this.$refs.handleBudget.add('add')
        })
      } else {
        this.$nextTick(() => {
          this.$refs.handlePayment.add('add')
        })
      }
    },

    changBrumb(type) {
      switch (type) {
        case 'add':
          let obj = {
            id: Math.random(),
            value: '新增'
          }
          this.breadList.splice(this.breadList.length, 0, obj)
          break
        case 'view':
          let obj1 = {
            id: Math.random(),
            value: '详情'
          }
          this.breadList.splice(this.breadList.length, 0, obj1)
          break
        default:
          break
      }
    },

    handleAdd1() {
      this.defaultpage = false
      this.componentName = 'policyDocument'
      this.$nextTick(() => {
        this.$refs.policyDocument.add('add')
      })
    },

    handleView(type, row) {
      let obj = {
        id: Math.random(),
        value: type == 'view' ? '详情' : '查看统计图'
      }
      this.breadList.splice(this.breadList.length, 0, obj)

      this.componentName = type
      this.type = type

      if (type == 'budget') {
        this.$nextTick(() => {
          this.$refs.handleBudget.view('view', row)
        })
      } else {
        this.$nextTick(() => {
          this.$refs.handlePayment.view('view', row)
        })
      }
    },

    refreshTable() {
      this.getPage()
    },

    // 选择select
    handleSelectionChange(selection) {
      // console.log(selection);//点击的那行数据arr
      this.selectedRows = selection
      this.showActions = selection.length > 0
    },
    isSelect({ row }) {
      const checkIdList = this.selectedRows.map(item => item.data1)
      if (checkIdList.includes(row.data1)) {
        return {
          color: '#e17009'
        }
      }
    },

    // 分页插件事件
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.getPage()
    },
    downloadfile(res) {
      var contentDisposition = res.headers['content-disposition']
      if (contentDisposition) {
        const regex = /filename*.+?\.xlsx/g
        const matches = contentDisposition.match(regex)
        const filename1 = matches[1].replace(/['"]/g, '')
        const startIndex = filename1.indexOf('filename*=UTF-8') + 'filename*=UTF-8'.length
        const result = filename1.substring(startIndex)
        const filename = result.replace(/^_+/g, '')
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(res.data) // 创建下载的链接
        var reg = /^["](.*)["]$/g
        downloadElement.style.display = 'none'
        downloadElement.href = href
        downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href)
      } else {
        this.$message.error('导出失败')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/elementui.scss';

::v-deep .el-form-item {
  margin-bottom: 0px;
}
</style>
